import React, { useEffect, useState } from "react";
import {
  AdresseModel,
  EmailModel,
  KontaktdatenRequestModel,
  KontaktdatenResponseType,
  KontaktdatenStatusModel,
  MitgliedschaftRequestType,
  StaatenkennungModel,
  StrasseSAModel,
  TelefonModel,
} from "../../../api";
import { KontaktdatenValidationModel, PropsViews } from "../model/PropsInterfaces";
import { ExtendedKontaktdaten } from "../model/Kontaktdaten";
import { mitgliedschaftService } from "../service/MitgliedschaftService";
import { Hinweis } from "../view/Hinweise";
import { AutoComplete } from "primereact/autocomplete";

import IconFAZEmail from "../svgs/IconFAZEmail";
import IconFAZFax from "../svgs/IconFAZFax";
import IconFAZMobil from "../svgs/IconFAZMobil";
import IconFAZTel from "../svgs/IconFAZTel";
import IconFAZWeb from "../svgs/IconFAZWeb";
import IconArrowRight from "../svgs/IconArrowRight";
import IconButtonEdit from "../svgs/IconButtonEdit";
import {
  MitgliedschaftKontaktEditStateEnum,
  MitgliedschaftKontaktEditingAreaEnum,
} from "../enum/MitgliedschaftViewStatsEnums";
import { statistikAustriaService } from "../service/StatistikAustriaService";
import { LaenderSelect } from "../form/Selects";
import { unchangedMessage, successMessage, errorMessage, changeRequestSentText } from "../view/KontaktdatenMessages";
import { URL_BENUTZERKONTO, URL_FIRMEN_AZ, getLink } from "../constants/urls";
import { ServiceHotlineInfo } from "../view/InfoComponents";
import { getDescriptionNameByRoleId } from "../service/UserService";

export default function KontaktdatenView(props: PropsViews) {
  const [loading, setLoading] = useState(false);
  const [laenderdaten, setLaenderdaten] = useState<Array<StaatenkennungModel>>();
  const [manuelleEingabe, setManuelleEingabe] = useState<boolean>(false);
  const [strassenQuery, setStrassenQuery] = useState<string>();
  const [strassenDaten, setStrassenDaten] = useState<Array<StrasseSAModel>>();
  const [editStateAddress, setEditStateAddress] = useState<MitgliedschaftKontaktEditStateEnum>(
    MitgliedschaftKontaktEditStateEnum.UNCHANGED
  );
  const [editStateTel, setEditStateTel] = useState<MitgliedschaftKontaktEditStateEnum>(
    MitgliedschaftKontaktEditStateEnum.UNCHANGED
  );
  const [editStateMobile, setEditStateMobile] = useState<MitgliedschaftKontaktEditStateEnum>(
    MitgliedschaftKontaktEditStateEnum.UNCHANGED
  );
  const [editStateEmail, setEditStateEmail] = useState<MitgliedschaftKontaktEditStateEnum>(
    MitgliedschaftKontaktEditStateEnum.UNCHANGED
  );
  const [kontaktdatenEnabled, setKontaktdatenEnabled] = useState<boolean>(false);
  const [kontaktdatenWKOBase, setKontaktdatenWKOBase] = useState<ExtendedKontaktdaten>();
  const [kontaktdatenChanged, setKontaktdatenChanged] = useState<ExtendedKontaktdaten>();
  const [kontaktdatenStatus, setKontaktdatenStatus] = useState<KontaktdatenStatusModel>({});
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showUnchangedMessage, setShowUnchangedMessage] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<KontaktdatenValidationModel>();
  const [processing, setProcessing] = useState<boolean>(false);

  const emptyKontaktdaten: ExtendedKontaktdaten = {
    email: "",
    hausnummer: "",
    mobil: "",
    ort: "",
    plz: "",
    postfach: "",
    strasse: "",
    strasseSA: undefined,
    tel: "",
    zusatz: "",
  };

  useEffect(() => {
    const requestParameterKontaktdatenFirmenAZ: MitgliedschaftRequestType = {};
    setLoading(true);

    mitgliedschaftService
      .isKontaktdatenEnabled()
      .then((enabled) => {
        if (enabled && enabled.data && enabled.data.data) {
          setKontaktdatenEnabled(true);
          mitgliedschaftService
            .getKontaktdaten(requestParameterKontaktdatenFirmenAZ)
            .then((kontaktdaten) => {
              let data: KontaktdatenResponseType = kontaktdaten.data;

              if (!laenderdaten) {
                statistikAustriaService
                  .getStatistikAustriaLaender("de")
                  .then((laenderdatenRes) => {
                    setLaenderdaten(laenderdatenRes.data);
                    loadData(data, laenderdatenRes.data);
                  })
                  .catch(() => {
                    setKontaktdatenEnabled(false);
                    setShowError(true);
                  });
              }
            })
            .catch(() => {
              setKontaktdatenEnabled(false);
              setShowError(true);
            });
        } else {
          setKontaktdatenEnabled(false);
        }
      })
      .catch(() => {
        setKontaktdatenEnabled(false);
        setShowError(true);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = (data: KontaktdatenResponseType, laender: Array<StaatenkennungModel>) => {
    if (data) {
      let kontaktdaten: ExtendedKontaktdaten = data;
      kontaktdaten.kontaktdatenGPDB = data.kontaktdaten?.filter(
        (kontakt) => kontakt.art && kontakt.art.indexOf("GP") === 0
      );
      kontaktdaten.kontaktdatenFAZ = data.kontaktdaten?.filter(
        (kontakt) => kontakt.art && kontakt.art.indexOf("GP") < 0
      );

      kontaktdaten.tel = data.kontaktdaten?.find((kontakt) => kontakt.art && kontakt.art === "GPTEL")?.wert;
      kontaktdaten.email = data.kontaktdaten?.find((kontakt) => kontakt.art && kontakt.art === "GPEM")?.wert;
      kontaktdaten.mobil = data.kontaktdaten?.find((kontakt) => kontakt.art && kontakt.art === "GPHAN")?.wert;
      kontaktdaten.vorname = data.vorname;
      kontaktdaten.nachname = data.zuname;
      kontaktdaten.strasse = data.zusendeadresse?.strassenName;
      kontaktdaten.hausnummer = data.zusendeadresse?.hausnummerVon;
      kontaktdaten.zusatz = data.zusendeadresse?.hausnummerZusatz;
      kontaktdaten.postfach = data.zusendeadresse?.postfach;
      kontaktdaten.plz = data.zusendeadresse?.plz;
      kontaktdaten.ort = data.zusendeadresse?.ortName;
      kontaktdaten.staatBezeichnung = data.zusendeadresse?.staatName;
      kontaktdaten.staat = convertLaenderBezeichnungToKuerzel(kontaktdaten.staatBezeichnung, laender);
      kontaktdaten.staatBezeichnung = convertLaenderKuerzelToBezeichnung(kontaktdaten.staat, laender);

      normalizeStrassenDaten(kontaktdaten);
      setKontaktdatenWKOBase(kontaktdaten);
      let changedKontaktdaten: ExtendedKontaktdaten = { ...kontaktdaten, ...emptyKontaktdaten };
      setKontaktdatenChanged(changedKontaktdaten);

      mitgliedschaftService
        .getKontaktdatenChangeRequestStatus({})
        .then((response) => {
          if (response.data.zusendeadresseGeaendert && response.data.zusendeadresseNeu) {
            setEditStateAddress(MitgliedschaftKontaktEditStateEnum.CHANGE_REQUESTED);
            mergeStatusData(response.data, kontaktdaten);
          }
          if (response.data.festnetzGeaendert) {
            setEditStateTel(MitgliedschaftKontaktEditStateEnum.CHANGE_REQUESTED);
          }
          if (response.data.mobilGeaendert) {
            setEditStateMobile(MitgliedschaftKontaktEditStateEnum.CHANGE_REQUESTED);
          }
          if (response.data.emailGeaendert) {
            setEditStateEmail(MitgliedschaftKontaktEditStateEnum.CHANGE_REQUESTED);
          }
          setKontaktdatenStatus(response.data);
        })
        .catch(() => {
          setShowError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const mergeStatusData = (data: KontaktdatenStatusModel, kontaktdaten: ExtendedKontaktdaten | undefined) => {
    if (kontaktdaten && data.zusendeadresseGeaendert && data.zusendeadresseNeu) {
      if (!data.zusendeadresseNeu.ort) {
        data.zusendeadresseNeu.ort = kontaktdaten.ort;
      }
      if (!data.zusendeadresseNeu.plz) {
        data.zusendeadresseNeu.plz = kontaktdaten.plz;
      }
      if (!data.zusendeadresseNeu.landIso) {
        data.zusendeadresseNeu.landIso = kontaktdaten.staat;
      }
    }
  };

  const validateValues = (
    area: MitgliedschaftKontaktEditingAreaEnum,
    kontaktdatenChanged: ExtendedKontaktdaten | undefined
  ): boolean => {
    if (area === MitgliedschaftKontaktEditingAreaEnum.ADDRESS) {
      let validationResult = checkForError(
        "hausnummer",
        kontaktdatenChanged?.hausnummer,
        undefined,
        kontaktdatenChanged
      );
      validationResult = checkForError("plz", kontaktdatenChanged?.plz, validationResult, kontaktdatenChanged);
      validationResult = checkForError("ort", kontaktdatenChanged?.ort, validationResult, kontaktdatenChanged);
      validationResult = checkForError("strasse", kontaktdatenChanged?.strasse, validationResult, kontaktdatenChanged);
      setValidationError(validationResult);
      return !validationResult;
    }
    return true;
  };

  const compareChanges = (area: MitgliedschaftKontaktEditingAreaEnum): KontaktdatenRequestModel | undefined => {
    let changed: boolean = false;
    let kontaktdatenChangeRequestModel: KontaktdatenRequestModel = {};

    if (area === MitgliedschaftKontaktEditingAreaEnum.ADDRESS) {
      let zusendeadresseAlt: AdresseModel = {};
      let zusendeadresseNeu: AdresseModel = {};

      zusendeadresseAlt.landIso = kontaktdatenWKOBase?.staat;
      zusendeadresseNeu.landIso = kontaktdatenChanged?.staat;
      if (kontaktdatenWKOBase?.staat !== kontaktdatenChanged?.staat) {
        changed = true;
      }

      zusendeadresseAlt.plz = kontaktdatenWKOBase?.plz;
      zusendeadresseNeu.plz = kontaktdatenChanged?.plz;
      if (kontaktdatenWKOBase?.plz !== kontaktdatenChanged?.plz) {
        changed = true;
      }

      zusendeadresseAlt.ort = kontaktdatenWKOBase?.ort;
      zusendeadresseNeu.ort = kontaktdatenChanged?.ort;
      if (kontaktdatenWKOBase?.ort !== kontaktdatenChanged?.ort) {
        changed = true;
      }

      zusendeadresseAlt.strasse = kontaktdatenWKOBase?.strasse;
      zusendeadresseNeu.strasse = kontaktdatenChanged?.strasse;
      if (kontaktdatenWKOBase?.strasse !== kontaktdatenChanged?.strasse) {
        changed = true;
      }

      zusendeadresseAlt.strasseSa = kontaktdatenWKOBase?.strasseSA;
      if (!manuelleEingabe) {
        zusendeadresseNeu.strasseSa = kontaktdatenChanged?.strasseSA;
      }
      if (kontaktdatenWKOBase?.strasseSA !== kontaktdatenChanged?.strasseSA) {
        changed = true;
      }

      zusendeadresseAlt.hausnummer = kontaktdatenWKOBase?.hausnummer;
      zusendeadresseNeu.hausnummer = kontaktdatenChanged?.hausnummer;
      if (zusendeadresseAlt.hausnummer !== zusendeadresseNeu.hausnummer) {
        changed = true;
      }

      zusendeadresseAlt.zusatz = kontaktdatenWKOBase?.zusatz;
      zusendeadresseNeu.zusatz = kontaktdatenChanged?.zusatz?.trim();
      if (zusendeadresseNeu.zusatz === "") {
        zusendeadresseNeu.zusatz = undefined;
      }
      if (zusendeadresseAlt.zusatz !== zusendeadresseNeu.zusatz) {
        changed = true;
      }

      zusendeadresseAlt.postfach = kontaktdatenWKOBase?.postfach;
      zusendeadresseNeu.postfach = kontaktdatenChanged?.postfach?.trim();
      if (zusendeadresseNeu.postfach === "") {
        zusendeadresseNeu.postfach = undefined;
      }
      if (zusendeadresseAlt.postfach !== zusendeadresseNeu.postfach) {
        changed = true;
      }

      if (changed) {
        kontaktdatenChangeRequestModel = {
          zusendeadresseAlt,
          zusendeadresseNeu,
        };
      }
    } else if (area === MitgliedschaftKontaktEditingAreaEnum.PHONE) {
      let festnetzAlt: TelefonModel = { typ: "Festnetz" };
      let festnetzNeu: TelefonModel = { typ: "Festnetz" };

      festnetzAlt.nummer = kontaktdatenWKOBase?.tel;
      festnetzNeu.nummer = kontaktdatenChanged?.tel?.trim();
      if (festnetzNeu.nummer === "") {
        festnetzNeu.nummer = undefined;
      }
      if (festnetzAlt.nummer !== festnetzNeu.nummer) {
        changed = true;
      }
      if (changed) {
        kontaktdatenChangeRequestModel = {
          festnetzAlt,
          festnetzNeu,
        };
      }
    } else if (area === MitgliedschaftKontaktEditingAreaEnum.MOBILE) {
      let mobilAlt: TelefonModel = { typ: "Mobil" };
      let mobilNeu: TelefonModel = { typ: "Mobil" };
      mobilAlt.nummer = kontaktdatenWKOBase?.mobil;
      mobilNeu.nummer = kontaktdatenChanged?.mobil?.trim();
      if (mobilNeu.nummer === "") {
        mobilNeu.nummer = undefined;
      }
      if (mobilAlt.nummer !== mobilNeu.nummer) {
        changed = true;
      }
      if (changed) {
        kontaktdatenChangeRequestModel = {
          mobilAlt,
          mobilNeu,
        };
      }
    } else if (area === MitgliedschaftKontaktEditingAreaEnum.EMAIL) {
      let emailAlt: EmailModel = {};
      let emailNeu: EmailModel = {};

      emailAlt.emailAdresse = kontaktdatenWKOBase?.email;
      emailNeu.emailAdresse = kontaktdatenChanged?.email?.trim();
      if (emailNeu.emailAdresse === "") {
        emailNeu.emailAdresse = undefined;
      }
      if (emailAlt.emailAdresse !== emailNeu.emailAdresse) {
        changed = true;
      }

      if (changed) {
        kontaktdatenChangeRequestModel = {
          emailAlt,
          emailNeu,
        };
      }
    }

    if (changed) {
      return kontaktdatenChangeRequestModel;
    } else {
      return undefined;
    }
  };

  const changeBearbeitungsstatus = (
    area: MitgliedschaftKontaktEditingAreaEnum,
    targetState: MitgliedschaftKontaktEditStateEnum
  ) => {
    if (targetState === MitgliedschaftKontaktEditStateEnum.CHANGE_REQUESTED) {
      if (!processing) {
        setProcessing(true);
        let validValues = validateValues(area, kontaktdatenChanged);
        let kontaktdatenChangeRequestModel = compareChanges(area);
        if (validValues) {
          if (kontaktdatenChangeRequestModel) {
            setShowUnchangedMessage(false);
            mitgliedschaftService
              .createKontaktdatenChangeRequest(kontaktdatenChangeRequestModel)
              .then((response) => {
                let kontaktdatenStatusNew: KontaktdatenStatusModel = { ...kontaktdatenStatus };

                if (area === MitgliedschaftKontaktEditingAreaEnum.ADDRESS) {
                  setEditStateAddress(targetState);
                  kontaktdatenStatusNew.zusendeadresseGeaendert = true;
                  kontaktdatenStatusNew.zusendeadresseNeu = kontaktdatenChangeRequestModel?.zusendeadresseNeu;
                  mergeStatusData(kontaktdatenStatusNew, kontaktdatenWKOBase);
                } else if (area === MitgliedschaftKontaktEditingAreaEnum.PHONE) {
                  setEditStateTel(targetState);
                  kontaktdatenStatusNew.festnetzGeaendert = true;
                  kontaktdatenStatusNew.festnetzNeu = kontaktdatenChangeRequestModel?.festnetzNeu;
                } else if (area === MitgliedschaftKontaktEditingAreaEnum.MOBILE) {
                  setEditStateMobile(targetState);
                  kontaktdatenStatusNew.mobilGeaendert = true;
                  kontaktdatenStatusNew.mobilNeu = kontaktdatenChangeRequestModel?.mobilNeu;
                } else if (area === MitgliedschaftKontaktEditingAreaEnum.EMAIL) {
                  setEditStateEmail(targetState);
                  kontaktdatenStatusNew.emailGeaendert = true;
                  kontaktdatenStatusNew.emailNeu = kontaktdatenChangeRequestModel?.emailNeu;
                }

                setShowSuccess(true);
                setKontaktdatenStatus(kontaktdatenStatusNew);
              })
              .catch(() => {
                setShowError(true);
              })
              .finally(() => {
                setProcessing(false);
              });
          } else {
            setShowUnchangedMessage(true);
            setProcessing(false);
          }
        } else {
          setProcessing(false);
        }
      }
    } else {
      setShowUnchangedMessage(false);
      setStrassenQuery(undefined);
      setValidationError(undefined);
      let changedKontaktdaten: ExtendedKontaktdaten = { ...kontaktdatenChanged, ...emptyKontaktdaten };
      setKontaktdatenChanged(changedKontaktdaten);

      if (changedKontaktdaten.staat !== "AT") {
        setManuelleEingabe(true);
      } else {
        setManuelleEingabe(false);
      }

      if (area === MitgliedschaftKontaktEditingAreaEnum.ADDRESS) {
        setEditStateAddress(targetState);
      } else if (area === MitgliedschaftKontaktEditingAreaEnum.PHONE) {
        setEditStateTel(targetState);
      } else if (area === MitgliedschaftKontaktEditingAreaEnum.MOBILE) {
        setEditStateMobile(targetState);
      } else if (area === MitgliedschaftKontaktEditingAreaEnum.EMAIL) {
        setEditStateEmail(targetState);
      }
    }
  };

  const css = `
    body:not(.doc).eService a:hover {
      color: #3D3F8C;
    }
  `;

  const headerLineWithEditButton = (title: string, area: MitgliedschaftKontaktEditingAreaEnum) => {
    return (
      <div
        className="kontaktdaten-header-with-edit-button"
        style={{
          border: "1px solid #dee2e6",
          borderWidth: "0 0 1px 0",
          display: "flex",
          alignItems: "flex-end",
          paddingBottom: "0.6rem",
          marginBottom: "1rem",
        }}
      >
        <div className="kontaktdaten-header-with-edit-button-title" style={{ flex: "1 1 200px" }}>
          <strong>{title}</strong>
        </div>
        {editStateAddress !== MitgliedschaftKontaktEditStateEnum.EDITING &&
          editStateTel !== MitgliedschaftKontaktEditStateEnum.EDITING &&
          editStateMobile !== MitgliedschaftKontaktEditStateEnum.EDITING &&
          editStateEmail !== MitgliedschaftKontaktEditStateEnum.EDITING &&
          ((area === MitgliedschaftKontaktEditingAreaEnum.ADDRESS &&
            editStateAddress === MitgliedschaftKontaktEditStateEnum.UNCHANGED) ||
            (area === MitgliedschaftKontaktEditingAreaEnum.PHONE &&
              editStateTel === MitgliedschaftKontaktEditStateEnum.UNCHANGED) ||
            (area === MitgliedschaftKontaktEditingAreaEnum.MOBILE &&
              editStateMobile === MitgliedschaftKontaktEditStateEnum.UNCHANGED) ||
            (area === MitgliedschaftKontaktEditingAreaEnum.EMAIL &&
              editStateEmail === MitgliedschaftKontaktEditStateEnum.UNCHANGED)) && (
            <button
              className="btn btn-secondary kontaktdaten-header-with-edit-button-button"
              style={{ flex: "0 0 80px" }}
              onClick={() => changeBearbeitungsstatus(area, MitgliedschaftKontaktEditStateEnum.EDITING)}
            >
              <IconButtonEdit></IconButtonEdit>
            </button>
          )}
      </div>
    );
  };

  const buttonGroup = (area: MitgliedschaftKontaktEditingAreaEnum) => {
    return (
      <div className="action-button mb-2">
        <>
          <button
            className="btn btn-secondary"
            onClick={() => changeBearbeitungsstatus(area, MitgliedschaftKontaktEditStateEnum.UNCHANGED)}
          >
            Abbrechen
          </button>
          &nbsp;
        </>
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            changeBearbeitungsstatus(area, MitgliedschaftKontaktEditStateEnum.CHANGE_REQUESTED);
          }}
          disabled={!!validationError || !!processing}
        >
          Speichern
        </button>
      </div>
    );
  };

  const updateKontaktdatenChanged = (fieldName: keyof ExtendedKontaktdaten, value: string) => {
    let newKontaktdaten = { ...kontaktdatenChanged, [fieldName]: value };
    if (fieldName === "staat") {
      newKontaktdaten.staatBezeichnung = convertLaenderKuerzelToBezeichnung(value);
      validateValues(MitgliedschaftKontaktEditingAreaEnum.ADDRESS, newKontaktdaten);
    }
    setKontaktdatenChanged(newKontaktdaten);
  };

  const inputField = (
    inputId: string,
    fieldName: keyof ExtendedKontaktdaten,
    label: string,
    cols: string,
    disabled?: boolean
  ) => {
    return (
      <>
        <div className={"element-input " + cols + " mb-3 pe-2"}>
          <div className={"element-" + fieldName}>
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id={inputId}
                value={kontaktdatenChanged?.[fieldName] as string}
                onChange={(e) => {
                  setValidationError(checkForError(fieldName, e.currentTarget.value, undefined, kontaktdatenChanged));
                  updateKontaktdatenChanged(fieldName, e.currentTarget.value);
                }}
                disabled={disabled}
              />
              <label htmlFor={inputId}>{label}</label>
              {validationError && validationError?.[fieldName] && (
                <small className="p-error">{validationError?.[fieldName]}</small>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const convertLaenderKuerzelToBezeichnung = (kuerzel: string | undefined, laender?: Array<StaatenkennungModel>) => {
    if (!kuerzel) {
      return "";
    } else {
      if (laender) {
        return laender?.find((element) => element.iso?.toLowerCase() === kuerzel.toLowerCase())?.bezeichnung;
      } else {
        return laenderdaten?.find((element) => element.iso?.toLowerCase() === kuerzel.toLowerCase())?.bezeichnung;
      }
    }
  };

  const convertLaenderBezeichnungToKuerzel = (
    bezeichnung: string | undefined,
    laenderdaten: Array<StaatenkennungModel>
  ): string | undefined => {
    return bezeichnung
      ? laenderdaten?.find((element) => element.bezeichnung?.toLowerCase() === bezeichnung.toLowerCase())?.iso
      : "AT";
  };

  const normalizeStrassenDaten = (kontaktdaten: ExtendedKontaktdaten) => {
    if (kontaktdaten.strasse && kontaktdaten.strasse.length > 0) {
      let strassenName = kontaktdaten.strasse;
      let strassenNameMatcher = strassenName.match(/^(\D*)([^/]*)[/]?([^/]*)[/]?([^/]*)[/]?([^/]*)$/i);
      if (strassenNameMatcher && strassenNameMatcher.length > 0) {
        kontaktdaten.strasse = strassenNameMatcher[1].trim();
        if (strassenNameMatcher[2].trim().length > 0) {
          kontaktdaten.hausnummer = strassenNameMatcher[2].trim();
        }
        if (strassenNameMatcher[3].trim().length > 0) {
          kontaktdaten.zusatz = strassenNameMatcher[3].trim();
        }
      }

      if (kontaktdaten.strasse && kontaktdaten.plz && kontaktdaten.ort) {
        statistikAustriaService
          .getStatistikAustriaStrassen(kontaktdaten.strasse + " " + kontaktdaten.plz + " " + kontaktdaten.ort)
          .then((data) => {
            if (data.data.length === 1) {
              let kontaktdatenChanged = { ...kontaktdaten };
              kontaktdatenChanged.strasseSA = data.data[0]?.kennziffer;
              setKontaktdatenWKOBase(kontaktdatenChanged);
            }
          });
      }
    }
  };

  const searchStreet = (event: { query: string }) => {
    statistikAustriaService
      .getStatistikAustriaStrassen(event.query)
      .then((data) => {
        setStrassenDaten(data.data);
      })
      .catch(() => {
        setShowError(true);
      });
  };

  const adressSearchTemplate = (item: StrasseSAModel) => {
    return (
      <div className="adress-item">
        <div>
          {item.strassenname} [{item.postleitzahl}&nbsp;{item.ortschaft}]
        </div>
      </div>
    );
  };

  const checkForError = (
    fieldName: keyof KontaktdatenValidationModel,
    value: string | undefined,
    validationErrors: KontaktdatenValidationModel | undefined,
    kontaktdatenChanged: ExtendedKontaktdaten | undefined
  ) => {
    let validationResult: KontaktdatenValidationModel;
    if (validationErrors) {
      validationResult = { ...validationErrors };
    } else {
      validationResult = { ...validationError };
    }

    if (fieldName === "plz") {
      if (!value) {
        validationResult[fieldName] = "Die Postleitzahl darf nicht leer sein.";
      } else {
        if (kontaktdatenChanged?.staat === "AT" && !/^[0-9]{4}$/i.test(value)) {
          validationResult[fieldName] = "Die Postleitzahl muss in Österreich aus genau 4 Zahlen bestehen.";
        } else {
          validationResult[fieldName] = undefined;
        }
      }
    }
    if (fieldName === "ort") {
      if (!value) {
        validationResult[fieldName] = "Der Ort darf nicht leer sein.";
      } else {
        validationResult[fieldName] = undefined;
      }
    }
    if (fieldName === "strasse") {
      if (!value && !kontaktdatenChanged?.postfach) {
        validationResult[fieldName] = "Die Strasse darf nicht leer sein.";
      } else {
        validationResult[fieldName] = undefined;
      }
    }
    if (fieldName === "hausnummer") {
      if (!value && !kontaktdatenChanged?.postfach) {
        validationResult[fieldName] = "Die Hausnummer darf nicht leer sein.";
      } else {
        validationResult[fieldName] = undefined;
      }
    }

    if (fieldName === "postfach") {
      if (value) {
        validationResult.strasse = undefined;
        validationResult.hausnummer = undefined;
      } else {
        if (!kontaktdatenChanged?.strasse) {
          validationResult.strasse = "Die Strasse darf nicht leer sein.";
        }
        if (!kontaktdatenChanged?.hausnummer) {
          validationResult.hausnummer = "Die Hausnummer darf nicht leer sein.";
        }
      }
    }

    if (fieldName === "email") {
      if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value)) {
        validationResult[fieldName] = "Bitte geben Sie eine korrekte Email Adresse an. Beispiel: muster@wko.at";
      } else {
        validationResult[fieldName] = undefined;
      }
    }

    if (fieldName === "tel") {
      if (value && !/^[0-9+ ()]{10,30}$/i.test(value)) {
        validationResult[fieldName] =
          "Bitte geben Sie eine korrekte Telefonnummer ein. Beispiel: +43 2774 123456";
      } else {
        validationResult[fieldName] = undefined;
      }
    }

    if (fieldName === "mobil") {
      if (value && !/^[0-9+ ()]{10,30}$/i.test(value)) {
        validationResult[fieldName] =
          "Bitte geben Sie eine korrekte Telefonnummer ein. Beispiel: +43 664 1234567";
      } else {
        validationResult[fieldName] = undefined;
      }
    }

    if (
      Object.keys(validationResult).every((key) => {
        const dynamicField: keyof KontaktdatenValidationModel = key as keyof KontaktdatenValidationModel;
        return validationResult[dynamicField] === undefined;
      })
    ) {
      return undefined;
    } else {
      return validationResult;
    }
  };

  return (
    <>
      <style>{css}</style>
      <main data-gtm-block="main">
        <section className="members bg--white" data-gtm-section="eServices - Kontaktdaten">
          <div className="container col-lg-7">
            <div className="members-header mb-5">
              <div className="headline">
                <div className="headline-bc">
                  <h1>Kontaktdaten</h1>
                  <h3 className="subtitle">
                    In diesem Bereich können Sie die Kontaktdaten Ihres Unternehmens verwalten.
                  </h3>
                </div>
              </div>
            </div>
            <div className="content">
              {(kontaktdatenEnabled && (
                <>
                  <h4>Kommunikation mit der WKO</h4>
                  <p>
                    Bitte halten Sie Ihre Kontaktdaten stets aktuell – so können wir Ihnen das bestmögliche Service
                    bieten.
                  </p>
                  <div className="hinweis">
                    <Hinweis
                      title="Interne Angaben"
                      message="Diese Information wird nicht nach außen weitergegeben und dient rein zur Übermittlung von Informationen durch die WKO."
                    />
                  </div>

                  {!loading && !showError && (
                    <>
                      {headerLineWithEditButton("Zusendeadresse", MitgliedschaftKontaktEditingAreaEnum.ADDRESS)}
                      {editStateAddress === MitgliedschaftKontaktEditStateEnum.UNCHANGED && (
                        <>
                          <p>{getDescriptionNameByRoleId(props.userInfo)}</p>
                          {kontaktdatenWKOBase?.strasse && kontaktdatenWKOBase?.hausnummer && (
                            <p>
                              {kontaktdatenWKOBase?.strasse}&nbsp;{kontaktdatenWKOBase?.hausnummer}
                              {kontaktdatenWKOBase?.zusatz ? "/" + kontaktdatenWKOBase?.zusatz : ""}
                            </p>
                          )}
                          {kontaktdatenWKOBase?.postfach && <p>Postfach: {kontaktdatenWKOBase?.postfach}</p>}
                          <p>
                            {kontaktdatenWKOBase?.plz}&nbsp;{kontaktdatenWKOBase?.ort}
                          </p>
                          <p>{kontaktdatenWKOBase?.staatBezeichnung}</p>
                        </>
                      )}
                      {editStateAddress === MitgliedschaftKontaktEditStateEnum.CHANGE_REQUESTED && (
                        <>
                          {kontaktdatenStatus?.zusendeadresseGeaendert && kontaktdatenStatus.zusendeadresseNeu && (
                            <>
                              <p>{getDescriptionNameByRoleId(props.userInfo)}</p>
                              {kontaktdatenStatus.zusendeadresseNeu.strasse &&
                                kontaktdatenStatus.zusendeadresseNeu.hausnummer && (
                                  <p>
                                    {kontaktdatenStatus.zusendeadresseNeu.strasse}&nbsp;
                                    {kontaktdatenStatus.zusendeadresseNeu.hausnummer}
                                    {kontaktdatenStatus.zusendeadresseNeu.zusatz
                                      ? "/" + kontaktdatenStatus.zusendeadresseNeu.zusatz
                                      : ""}
                                  </p>
                                )}
                              {kontaktdatenStatus.zusendeadresseNeu.postfach && (
                                <p>Postfach: {kontaktdatenStatus.zusendeadresseNeu.postfach}</p>
                              )}
                              <p>
                                {kontaktdatenStatus.zusendeadresseNeu?.plz}&nbsp;
                                {kontaktdatenStatus.zusendeadresseNeu?.ort}
                              </p>
                              <p>{convertLaenderKuerzelToBezeichnung(kontaktdatenStatus.zusendeadresseNeu.landIso)}</p>
                            </>
                          )}
                          {changeRequestSentText}
                        </>
                      )}
                      {editStateAddress === MitgliedschaftKontaktEditStateEnum.EDITING && (
                        <>
                          <form autoComplete="no">
                            <p>{getDescriptionNameByRoleId(props.userInfo)}</p>
                            <div className="input-group" style={{ display: "flex" }}>
                              <div className={"element-input col-12 mb-3"}>
                                <div className={"element-staat"}>
                                  <LaenderSelect
                                    data={laenderdaten}
                                    value={kontaktdatenChanged?.staat || "AT"}
                                    onChangeHandler={(e: any) => {
                                      let newValue = e.currentTarget.value;
                                      updateKontaktdatenChanged("staat", newValue);
                                      if (newValue !== "AT") {
                                        setManuelleEingabe(true);
                                        setStrassenQuery(undefined);
                                      }
                                    }}
                                    style={{ width: "99%" }}
                                  ></LaenderSelect>
                                </div>
                              </div>
                            </div>
                            <div className="input-group" style={{ display: "flex" }}>
                              <div className={"element-input col-10 mb-3"}>
                                <AutoComplete
                                  value={strassenQuery}
                                  suggestions={strassenDaten}
                                  completeMethod={searchStreet}
                                  field="strassenname"
                                  itemTemplate={adressSearchTemplate}
                                  dropdown
                                  onChange={(e) => {
                                    if (e.value) {
                                      if (typeof e.value === "string") {
                                        setStrassenQuery(e.value);
                                      } else {
                                        var street: StrasseSAModel = e.value;
                                        let newKontaktdaten = { ...kontaktdatenChanged };
                                        newKontaktdaten.strasse = street.strassenname;
                                        newKontaktdaten.plz = street.postleitzahl;
                                        newKontaktdaten.ort = street.gemeinde;
                                        newKontaktdaten.strasseSA = street.kennziffer;
                                        setKontaktdatenChanged(newKontaktdaten);
                                        validateValues(MitgliedschaftKontaktEditingAreaEnum.ADDRESS, newKontaktdaten);
                                      }
                                    }
                                  }}
                                  aria-label="Adresse"
                                  placeholder={
                                    kontaktdatenChanged?.staat !== "AT"
                                      ? "Die Straßensuche ist nur innerhalb von Österreich möglich"
                                      : manuelleEingabe
                                      ? "Manuelle Eingabe aktiviert"
                                      : "Suchen Sie hier Ihre Straße (Straße, Ort, PLZ) und wählen Sie diese aus"
                                  }
                                  dropdownAriaLabel="Wählen Sie eine Adresse aus"
                                  autoComplete="no"
                                  style={{ width: "99%" }}
                                  disabled={manuelleEingabe}
                                />
                              </div>
                              <div className={"element-input col-2 mb-3"}>
                                <div className={"element-manuelle-eingabe"}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="manuelle-eingabe"
                                      checked={manuelleEingabe}
                                      onChange={(e) => setManuelleEingabe(e.currentTarget.checked)}
                                      disabled={kontaktdatenChanged?.staat !== "AT"}
                                    />
                                    <label className="form-check-label" htmlFor="manuelle-eingabe">
                                      manuelle Eingabe
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="input-group" style={{ display: "flex" }}>
                              {inputField("input_strasse", "strasse", "Straße", "col-6", !manuelleEingabe)}
                              {inputField("input_hausnummer", "hausnummer", "Hausnummer", "col-2")}
                              {inputField("input_zusatz", "zusatz", "Zusatz", "col-2")}
                              {inputField("input_postfach", "postfach", "Postfach", "col-2")}
                              {inputField("input_plz", "plz", "PLZ", "col-4", !manuelleEingabe)}
                              {inputField("input_ort", "ort", "Ort", "col-8", !manuelleEingabe)}
                            </div>
                            {buttonGroup(MitgliedschaftKontaktEditingAreaEnum.ADDRESS)}
                            {showUnchangedMessage && unchangedMessage}
                          </form>
                        </>
                      )}
                      <p>&nbsp;</p>

                      {headerLineWithEditButton("Telefon - Festnetz", MitgliedschaftKontaktEditingAreaEnum.PHONE)}
                      {editStateTel === MitgliedschaftKontaktEditStateEnum.UNCHANGED && (
                        <>
                          {!kontaktdatenWKOBase?.tel && (
                            <p>Derzeit ist keine Festnetz Telefonnummer bei dieser Mitgliedschaft hinterlegt.</p>
                          )}
                          {kontaktdatenWKOBase?.tel && (
                            <p>
                              <IconFAZTel />
                              <span className="ms-3">{kontaktdatenWKOBase?.tel}</span>
                            </p>
                          )}
                        </>
                      )}
                      {editStateTel === MitgliedschaftKontaktEditStateEnum.CHANGE_REQUESTED && (
                        <>
                          {(kontaktdatenStatus?.festnetzGeaendert && (
                            <>
                              {(kontaktdatenStatus.festnetzNeu && kontaktdatenStatus.festnetzNeu.nummer && (
                                <p>
                                  <IconFAZTel />
                                  <span className="ms-3">{kontaktdatenStatus.festnetzNeu.nummer}</span>
                                </p>
                              )) || (
                                <p>
                                  <IconFAZTel />
                                  <span className="ms-3">Nummer wird gelöscht</span>
                                </p>
                              )}
                            </>
                          )) ||
                            (kontaktdatenWKOBase?.tel && (
                              <p>
                                <IconFAZTel />
                                <span className="ms-3">{kontaktdatenWKOBase.tel}</span>
                              </p>
                            ))}
                          {changeRequestSentText}
                        </>
                      )}
                      {editStateTel === MitgliedschaftKontaktEditStateEnum.EDITING && (
                        <>
                          <p>Lassen Sie das Feld leer, wenn Sie den Eintrag löschen wollen.</p>
                          {inputField("input_tel", "tel", "Telefon", "col-12")}
                          {buttonGroup(MitgliedschaftKontaktEditingAreaEnum.PHONE)}
                          {showUnchangedMessage && unchangedMessage}
                        </>
                      )}
                      <p>&nbsp;</p>

                      {headerLineWithEditButton("Telefon - Mobil", MitgliedschaftKontaktEditingAreaEnum.MOBILE)}
                      {editStateMobile === MitgliedschaftKontaktEditStateEnum.UNCHANGED && (
                        <>
                          {!kontaktdatenWKOBase?.tel && !kontaktdatenWKOBase?.mobil && (
                            <p>Derzeit ist keine mobile Telefonnummer bei dieser Mitgliedschaft hinterlegt.</p>
                          )}
                          {kontaktdatenWKOBase?.mobil && (
                            <p>
                              <IconFAZMobil />
                              <span className="ms-3">{kontaktdatenWKOBase?.mobil}</span>
                            </p>
                          )}
                        </>
                      )}
                      {editStateMobile === MitgliedschaftKontaktEditStateEnum.CHANGE_REQUESTED && (
                        <>
                          {(kontaktdatenStatus?.mobilGeaendert && (
                            <>
                              {(kontaktdatenStatus.mobilNeu && kontaktdatenStatus.mobilNeu.nummer && (
                                <p>
                                  <IconFAZMobil />
                                  <span className="ms-3">{kontaktdatenStatus.mobilNeu.nummer}</span>
                                </p>
                              )) || (
                                <p>
                                  <IconFAZMobil />
                                  <span className="ms-3">Nummer wird gelöscht</span>
                                </p>
                              )}
                            </>
                          )) ||
                            (kontaktdatenWKOBase?.mobil && (
                              <p>
                                <IconFAZMobil />
                                <span className="ms-3">{kontaktdatenWKOBase.mobil}</span>
                              </p>
                            ))}

                          {changeRequestSentText}
                        </>
                      )}
                      {editStateMobile === MitgliedschaftKontaktEditStateEnum.EDITING && (
                        <>
                          <p>Lassen Sie das Feld leer, wenn Sie den Eintrag löschen wollen.</p>
                          {inputField("input_mobil", "mobil", "Mobil", "col-12")}
                          {buttonGroup(MitgliedschaftKontaktEditingAreaEnum.MOBILE)}
                          {showUnchangedMessage && unchangedMessage}
                        </>
                      )}
                      <p>&nbsp;</p>

                      {headerLineWithEditButton(
                        "E-Mail Adresse für Zusendungen",
                        MitgliedschaftKontaktEditingAreaEnum.EMAIL
                      )}
                      {editStateEmail === MitgliedschaftKontaktEditStateEnum.UNCHANGED && (
                        <>
                          {!kontaktdatenWKOBase?.email && <p>Derzeit ist keine E-Mail Adresse hinterlegt.</p>}
                          {kontaktdatenWKOBase?.email && (
                            <p>
                              <IconFAZEmail />
                              <span className="ms-3">{kontaktdatenWKOBase?.email}</span>
                            </p>
                          )}
                        </>
                      )}
                      {editStateEmail === MitgliedschaftKontaktEditStateEnum.CHANGE_REQUESTED && (
                        <>
                          {(kontaktdatenStatus?.emailGeaendert && (
                            <>
                              {(kontaktdatenStatus.emailNeu && kontaktdatenStatus.emailNeu?.emailAdresse && (
                                <p>
                                  <IconFAZEmail />
                                  <span className="ms-3">{kontaktdatenStatus.emailNeu?.emailAdresse}</span>
                                </p>
                              )) || (
                                <p>
                                  <IconFAZEmail />
                                  <span className="ms-3">Email wird gelöscht</span>
                                </p>
                              )}
                            </>
                          )) || <p>{kontaktdatenWKOBase?.email}</p>}
                          {changeRequestSentText}
                        </>
                      )}
                      {editStateEmail === MitgliedschaftKontaktEditStateEnum.EDITING && (
                        <>
                          <p>Lassen Sie das Feld leer, um die E-Mail aus Ihren Daten zu löschen.</p>
                          {inputField("input_email", "email", "E-Mail", "col-12")}
                          {buttonGroup(MitgliedschaftKontaktEditingAreaEnum.EMAIL)}
                          {showUnchangedMessage && unchangedMessage}
                        </>
                      )}
                    </>
                  )}
                  {showSuccess && successMessage}
                  {showError && errorMessage}

                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </>
              )) || (
                <>
                  {false && (
                    <>
                      <h4>Kommunikation mit der WKO</h4>
                      <div className="hinweis">
                        <Hinweis
                          title="Service bald verfügbar"
                          message="Diese Service ist für ihre Landeskammer noch nicht freigegeben."
                        />
                      </div>
                      <p>&nbsp;</p>
                      <p>&nbsp;</p>
                    </>
                  )}
                </>
              )}

              <h4>Kontaktdaten im Firmen A-Z</h4>
              <div>
                <Hinweis
                  title="Firmen A-Z"
                  message="Die öffentlich verfügbaren Kontaktdaten im WKO Firmen A-Z helfen Unternehmen und potenziellen Kunden, Sie schnell und einfach zu erreichen."
                />
                {(kontaktdatenWKOBase?.kontaktdatenFAZ && kontaktdatenWKOBase.kontaktdatenFAZ.length > 0 && (
                  <>
                    <p>&nbsp;</p>
                    {kontaktdatenWKOBase.kontaktdatenFAZ?.map((kontakt, index) => {
                      return (
                        <p key={index}>
                          {kontakt.art === "EM" && <IconFAZEmail />}
                          {kontakt.art === "FAX" && <IconFAZFax />}
                          {kontakt.art === "HAN" && <IconFAZMobil />}
                          {kontakt.art === "TEL" && <IconFAZTel />}
                          {kontakt.art === "URL" && <IconFAZWeb />}
                          {kontakt.art && kontakt.art.indexOf("GP") < 0 && (
                            <>
                              &nbsp;<span className="ms-3">{kontakt.wert}</span>
                            </>
                          )}
                        </p>
                      );
                    })}
                  </>
                )) || (
                  <>
                    <p>&nbsp;</p>
                    <p>Zurzeit sind keine Kontaktdaten im Firmen A-Z hinterlegt.</p>
                  </>
                )}
              </div>
              <div>
                <p>&nbsp;</p>
                <p>
                  <a href={getLink(URL_FIRMEN_AZ)} target="_blank" rel="noreferrer">
                    <strong>Firmen A-Z Profil bearbeiten</strong> <IconArrowRight></IconArrowRight>
                  </a>
                </p>
              </div>
              <p>&nbsp;</p>
              <p>&nbsp;</p>

              <h4>E-Mail Adresse zur Anmeldung auf wko.at und weiteren WKO Services</h4>
              <p>Sie sind zur Zeit angemeldet als</p>
              <p>
                <IconFAZEmail />
                &nbsp;<span className="ms-3">{props.userInfo?.wkisEmail}</span>
              </p>
              <Hinweis
                title="Benutzerkonto"
                message="Um Ihre persönlichen Daten zu verwalten, Ihr Passwort zu ändern, sowie um ein neues Unternehmen zuzuordnen, wechseln Sie bitte zu Ihrem Benutzerkonto."
              />
              <div>
                <p>&nbsp;</p>
                <p>
                  <a href={getLink(URL_BENUTZERKONTO)} target="_blank" rel="noreferrer">
                    <strong>Zum Benutzerkonto</strong> <IconArrowRight></IconArrowRight>
                  </a>
                </p>
              </div>
            </div>
            <ServiceHotlineInfo hotlineKey="kontaktdaten" userInfo={props.userInfo} />
          </div>
        </section>
      </main>
    </>
  );
}
